.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateY(100%);
}

.slide-enter.slide-enter-active {
  transform: translateY(0%);
}

.slide-exit {
  display: none;
  flex-flow: column;
  max-height: 100vh;
  margin-left: 2rem;
  transform: translateY(0%);
}

.slide-exit-active {
  transform: translateY(-100%);
}
