@import url("https://fonts.googleapis.com/css?family=Overpass:300,400&display=swap");

body {
  background: #151515;
  max-height: 100vh;
  color: #fff;
  margin: 0;
  font-size: 16px;
  font-family: "Overpass", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  body {
    font-size: 16px;
  }
}
